.favorites {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  display: flex;
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.favorites__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  height: 100vh;
  width: 100vw;
  right: 0;
  top: 0;
  padding: 1rem;
  background: rgba(36, 36, 36, 0.75);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: scroll;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  animation: slideUp 0.3s ease forwards;
}

.favorites__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.favorites__closeBtn {
  display: flex;
  justify-content: flex-end;
}

.favorites__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  animation: slideIn 0.5s ease forwards;
}

.favorites__title h2 {
  text-align: center;
  font-size: 22px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 0;
}

.favorites__title p {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: #919191;
  margin-top: 0;
}

.favorites__button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 45px;
  width: 45px;
  font-size: 36px;
  background: none;
  border: none;
  border-radius: 5px;
  color: #ef473a;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.favorites__button:hover {
  color: #ff5f53;
}

.favorites__button span {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #000;
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(0.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0px);
  }
}