@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  padding: 0;
  margin: 0;
  background-color: #1e1e1e;
  color: white;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}
