.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  margin-top: 1rem;
}

.main__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.main__title h2 {
  font-size: 26px;
  font-weight: 300;
  color: #3b3b3b;
}

.main__not-found {
  display: flex;
  justify-content: center;
  width: 100%;
}

.main__not-found p {
  font-weight: 200;
}

.main__content {
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  overflow: auto;
}

.main__movie {
  padding-left: 0.5rem;
}

.main__illustration {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 1rem;
  overflow: hidden;
}

.main__illustration img {
  height: auto;
  max-width: 100%;
  opacity: 100%;
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(0.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}
