.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 1rem;
}

.pagination button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  font-size: 12px;
  color: rgb(190, 190, 190);
  background: #2a2a2a;
  border: none;
  border-left: 1px solid #3b3b3b;
  border-right: 1px solid #3b3b3b;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.pagination__button--prev, 
.pagination__button--next {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pagination button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  border-left: 1px solid #3b3b3b;
}

.pagination button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  border-right: 1px solid #3b3b3b;
}

.pagination button:hover {
  background: #3b3b3b;
}

.current {
  transform: scale(1.15);
  border: 1px solid #ef473a;
}
