.app {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media only screen and (max-width: 595px) {
  .app {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (min-width: 600px) {
  .app {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media only screen and (min-width: 768px) {
  .app {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media only screen and (min-width: 889px) {
  .app {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media only screen and (min-width: 1200px) {
  .app {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
