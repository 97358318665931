.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #303030;
  z-index: 50;
}

.header__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
}

.header__logo img {
  width: 200px;
  height: auto;
}

.header__input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;
}

.header__input input {
  height: 40px;
  max-width: 280px;
  background: #2a2a2a;
  font-size: 16px;
  font-weight: 300;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #606060;
  padding-left: 1rem;
  outline: #ef473a;
}

.header__input button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 50px;
  font-size: 22px;
  color: #ef473a;
  background: #2a2a2a;
  border-left: 1px solid #3b3b3b;
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.header__input button:hover {
  background: #3b3b3b;
}

@media only screen and (min-width: 600px) {
  .header {
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .header__logo {
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;
  }
}
