#halfCircle {
  animation: spin 0.8s ease-in-out infinite;
  transform-origin: center;
}

#letter {
  animation: pulse 1.6s ease-in-out infinite;
  transform-origin: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
