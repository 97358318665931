.movie__card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
}

.movie__card--overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 230px;
  height: 320px;
  border-radius: 5px;
  z-index: 50;
  background-color: #3b3b3b;
  filter: grayscale(1);
  transition: all 0.3s ease-in-out;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  outline: 3px solid transparent;
  transition: all 0.7s;
  animation: expand 1s ease forwards;
}

.movie__card--overlay:hover {
  filter: grayscale(0);
  outline: 3px solid #cb2d3e;
  transform: scale(1.05);
  transition: all 1s;
  background-size: 125%;
}

.movie__card--overlay:hover .movie__card--fav-icon {
  opacity: 100%;
}

.movie__card--fav-icon {
  position: absolute;
  opacity: 50%;
  right: 1rem;
  top: 1rem;
  font-size: 28px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  color: #cb2d3e;
}

.movie__card--details {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 1rem;
  bottom: 0;
  left: 0;
  width: 230px;
  height: 320px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 5px;
}

.movie__card--details h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.movie__card--overlay p {
  margin: 0;
  font-weight: 200;
}

.movie__card--overlay span {
  font-weight: 300;
}

@keyframes expand {
  0% {
    transform: translateX(1400px);
  }
  100% {
    transform: translateX(0px);
  }
}
